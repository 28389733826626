









































































import { Component, Vue } from 'vue-property-decorator';
import { getUser } from '@/firebase-app';
import firebase from 'firebase';
import User = firebase.User;

@Component
export default class HomePage extends Vue {
  private user: User | null = null;

  private created() {
    this.user = getUser();
  }
}
